
  import { defineComponent } from "vue";
  import Card from "primevue/card";

  export default defineComponent({
    name: "Cards",
    components: {
      Card,
    },
    props: {
      name: String,
      src: String,
      aside: String,
      content: Array,
      urls: Array,
      id: Number,
      handleOnClick: Function
    },
    emits: ['handleOnClick'],
    methods: {
      handleClick(cardId: number, cardUrl: string) {
        this.$emit('handleOnClick', {cardId, cardUrl})
      }
    }
  });
