
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import * as ClickServices from "../api/helpers/Click";
import { useGtm } from "vue-gtm";
import * as ApplicationServices from "../api/helpers/Application";
import * as CardServices from "../api/helpers/Cards";
import Spinner from "../components/Spinner.vue";
import Cards from "../components/Cards.vue";
import { getCurrentInstance } from "vue";
import * as FacebookServices from "../api/helpers/Facebook";
import "particles.js";
export default defineComponent({
  name: "Results",
  components: {
    Spinner,
    Cards,
  },
  data() {
    return {
      newEmail: sessionStorage.getItem("newEmail"),
      leadId: sessionStorage.getItem("leadId"),
      applicationId: sessionStorage.getItem("applicationId"),
      spinnerColor: process.env.VUE_APP_SPINNER_COLOR,
      spinnerTextColor: process.env.VUE_APP_SPINNER_TEXT_COLOR,
      sourceId: sessionStorage.getItem("CHID"),
    };
  },
  setup() {
    const app = getCurrentInstance();
    const route = useRoute();
    const toastTime = app?.appContext.config.globalProperties.$toastTime;
    const toast = app?.appContext.config.globalProperties.$toast;
    const sourceId = sessionStorage.getItem("CHID");
    const CHID =
      sessionStorage.getItem("CHID") ||
      (route.query.CHID as string | undefined);
    var cards = ref([]);
    var loading = ref(false);
    var redirect = ref(false);
    var foundVehicles = ref(false);
    const getCards = async (leadId: string | null) => {
      loading.value = true;
      if (leadId) {
        await CardServices.find(leadId).then(async (res) => {
          let { data } = res.data;
          if (data.length) {
            foundVehicles.value = true;
          } else {
            CardServices.findAll().then((res) => {
              data = res.data.data;
            });
          }
          data = data.map((c: any) => {
            c.content = c.content.split(String.fromCharCode(10));
            c.urls = c.urls.map((data: any) => ({
              ...data,
              url: updateURL(data.url, CHID),
            }));
            return c;
          });
          cards.value = data;
          loading.value = false;
        });
        if (cards.value.length == 1) {
          loading.value = true;
          setTimeout(function() {
            loading.value = false;
            redirect.value = true;

            setTimeout(function() {
              handleOnClick(
                {
                  cardId: cards.value[0]["id"],
                  cardUrl: cards.value[0]["urls"][0]["url"],
                },
                leadId,
                sourceId
              );
            }, 2500);
          }, 2500);
        }
      } else {
        await CardServices.findAll().then(async (res) => {
          let { data } = res.data;
          data = data.map((c: any) => {
            c.content = c.content.split(String.fromCharCode(10));
            c.urls = c.urls.map((data: any) => ({
              ...data,
              url: updateURL(data.url, CHID),
            }));
            return c;
          });
          cards.value = data;
          loading.value = false;
        });
        if (cards.value.length == 1) {
          loading.value = true;
          setTimeout(function() {
            loading.value = false;
            redirect.value = true;

            setTimeout(function() {
              handleOnClick(
                {
                  cardId: cards.value[0]["id"],
                  cardUrl: cards.value[0]["urls"][0]["url"],
                },
                leadId,
                sourceId
              )
            }, 2500);
          }, 2500);
        }
      }
    };

    const updateURL = (url: string, CHID?: string): string => {
      const urlObj = new URL(url);
      const searchParams = new URLSearchParams(urlObj.search);

      if (CHID !== undefined) {
        const currentSID = searchParams.get("SID");
        const newSID = currentSID ? `${currentSID},${CHID}` : CHID;
        searchParams.set("SID", newSID);
        urlObj.search = searchParams.toString();
      }

      return urlObj.toString();
    };

    const handleOnClick = async (
      params: { cardId: number; cardUrl: string },
      leadId?: any,
      sourceId?: any
    ) => {
      let newUrl = params.cardUrl;
      if (leadId) {
        const click = await ClickServices.click({
          leadId: leadId,
          cardId: params.cardId,
          source: sourceId,
        });

        const queryParam = "LGID";
        newUrl = addQueryParam(params.cardUrl, queryParam, click.data.data.id);
      }
      window.open(newUrl, "_self");
    };

    const addQueryParam = (url: string, paramKey: string, value: string) => {
      const urlObject = new URL(url);
      urlObject.searchParams.set(paramKey, value);

      return urlObject.toString();
    };

    return {
      cards,
      getCards,
      loading,
      redirect,
      foundVehicles,
      toastTime,
    };
  },
  mounted() {
    FacebookServices.pageView();
    this.initParticles();
    this.loading = true;
    window.scrollTo(0, 0);
    document.title = process.env.VUE_APP_TITLE + " | Resultados";

    this.getCards(this.leadId);

    if (this.newEmail != null) {
      window.dataLayer?.push({
        leadId: this.leadId,
        applicationId: this.applicationId,
        newEmail: this.newEmail,
        eventID: sessionStorage.getItem("eventID"),
      });
      FacebookServices.lead();
      this.triggerEvent();
      sessionStorage.removeItem("newEmail");
    }
    const application = this.applicationId?.toString();
    if (application) {
      ApplicationServices.update({ completed: true }, application);
    }
  },
  // beforeCreate() {
  //   const leadId = sessionStorage.getItem("leadId");
  //   const applicationId = sessionStorage.getItem("applicationId");
  //   if (!leadId || !applicationId) {
  //     this.$router.push("../registro");
  //   }
  // },
  methods: {
    initParticles() {
      window.particlesJS("particles-js", {
        particles: {
          number: {
            value: 250,
            density: {
              enable: true,
              value_area: 700,
            },
          },
          color: {
            value: "#ffffff",
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#000000",
            },
            polygon: {
              nb_sides: 10,
            },
          },
          opacity: {
            value: 0.75,
            random: true,
            anim: {
              enable: true,
              speed: 0,
              opacity_min: 0.2,
              sync: true,
            },
          },
          size: {
            value: 3,
            random: true,
            anim: {
              enable: true,
              speed: 0,
              size_min: 50,
              sync: false,
            },
          },
          line_linked: {
            enable: true,
            distance: 0,
            color: "#ffffff",
            opacity: 0.4,
            width: 1,
          },
          move: {
            enable: true,
            speed: 2,
            direction: "none",
            random: true,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: false,
              mode: "grab",
            },
            onclick: {
              enable: true,
              mode: "push",
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 140,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
      });
    },
    async handleOnClick(params: { cardId: number; cardUrl: string }) {
      const click = await ClickServices.click({
        leadId: this.leadId,
        cardId: params.cardId,
        source: this.sourceId,
      });

      const queryParam = "LGID";
      const newUrl = this.addQueryParam(
        params.cardUrl,
        queryParam,
        click.data.data.id
      );
      window.open(newUrl, "_blank");
    },
    triggerEvent() {
      const gtm = useGtm();
      gtm?.trackEvent({
        event: "FireGTM",
      });
    },
    addQueryParam(url: string, paramKey: string, value: string) {
      const urlObject = new URL(url);
      urlObject.searchParams.set(paramKey, value);

      return urlObject.toString();
    },
  },
});
